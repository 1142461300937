export class UserData {
    login: boolean;
    client: string;
    name: string;
    token: string;
    id: number;
    client_group_id: number;

    constructor(model) {
        this.login = model.login;
        this.client = model.client;
        this.name = model.name;
        this.token = model.token;
        this.id = +model.id;
        this.client_group_id = +model.client_group_id;
    }
}